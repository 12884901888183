import React from 'react';
import './Services.css';
import { Box, Grid, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMaximize, faDatabase } from '@fortawesome/fontawesome-free-solid';
import { faNoteSticky, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faAddressCard } from '@fortawesome/fontawesome-free-regular';
import { faFigma } from '@fortawesome/free-brands-svg-icons';

const Services = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));

  const styles = {
    mainContainer: {
      width: '100%',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    gridContainer: {
      width: '100%',
      padding: isMd ? '1rem' : '0',
    },
    textContainer: {
      marginLeft: isMd ? '0%' : '15%',
      color: 'white',
      textAlign: 'left',
      padding: isMd ? '1.5em' : '0',
    },
    heading: {
      fontSize: isXs ? '25px' : isSm ? '30px' : '35px',
      fontWeight: 'bold',
      marginBottom: '0rem',
      lineHeight: '1',
      fontFamily: 'Paytone One',
      textDecoration: 'underline',
      textDecorationColor: 'teal',
      marginTop: isSm ? '-5rem' : '0%',
    },
    subText: {
      fontSize: isXs ? '16px' : isSm ? '18px' : '22px',
      fontFamily: 'Philosopher',
      textAlign: isMd ? 'center' : 'left',
      marginBottom: isMd ? '2rem' : '8rem',
      marginRight: '2rem',
    },
    listContainer: {
      marginTop: '5%',
      color: 'white',
    },
    serviceList: {
      marginTop: '10%',
    },
    additionalList: {
      marginLeft: isMd ? '0%' : '10%',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listItem: {
      fontFamily: 'Philosopher',
      fontSize: '20px',
      textDecoration: 'underline',
      textDecorationColor: 'teal',
      fontWeight: 'bold',
    },
  };

  return (
    <div className='main-6'>
      <Box className="background-image6">
        <Box sx={styles.mainContainer}>
          <Grid container spacing={0} columns={16} sx={styles.gridContainer}>
            <Grid item xs={16} sm={8} md={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div style={styles.textContainer}>
                <Typography style={styles.heading}>SERVICES</Typography>

                <Typography className='top' style={styles.subText}>
                  <Grid container spacing={1} columns={16} sx={styles.listContainer}>
                    <List style={styles.serviceList}>
                      <ListItem className="fade-in"><FontAwesomeIcon style={{ paddingRight: "1rem", color: "#d50000" }} icon={faNoteSticky} /> Developing Landing Sites </ListItem>
                      <ListItem className="fade-in"><FontAwesomeIcon style={{ paddingRight: "1rem", color: "#4fc3f7" }} icon={faAddressCard} />Developing Company Profiles</ListItem>
                      <ListItem className="fade-in"><FontAwesomeIcon style={{ paddingRight: "1rem", color: "#d4e157" }} icon={faWindowMaximize} />Developing Custom Websites </ListItem>
                      <ListItem className="fade-in"><FontAwesomeIcon style={{ paddingRight: "1rem", color: "#2e7d32" }} icon={faDatabase} />Developing e-commerce Websites</ListItem>
                      <ListItem className="fade-in"><FontAwesomeIcon style={{ paddingRight: "1rem", color: "#d7ccc8" }} icon={faFigma} />Redesign Websites</ListItem>
                    </List>
                  </Grid>
                </Typography>
                <div style={styles.additionalList}>
                  <List>
                    <ListItem className="fade-in"sx={{fontFamily:'Philosopher',fontWeight:'bold'}}><FontAwesomeIcon style={{ paddingRight: "1rem", color: "teal" }} icon={faSquareCheck} /> Responsive Designs </ListItem>
                    <ListItem sx={{fontFamily:'Philosopher',fontWeight:'bold'}} className="fade-in"><FontAwesomeIcon style={{ paddingRight: "1rem", color: "teal" }} icon={faSquareCheck} />Hosting Services</ListItem>
                  </List>
                </div>
              </div>
            </Grid>
            <Grid item xs={0} sm={8} md={10}></Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Services;
