import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography'; // Import Typography
import one from '../Assets/Murouj-Business-Solutions-09-28-2024_11_59_PM.png';
import two from '../Assets/Hiyam-Cosmetics-Home-09-28-2024_11_58_PM.png';
import three from '../Assets/The-Leadership-Bureau-09-29-2024_12_01_AM.png';
import four from '../Assets/alert-security-09-29-2024_12_00_AM.png';
import five from '../Assets/M-A-B-Career-09-28-2024_11_58_PM.png';
import six from '../Assets/Screenshot (732).png';
import seven from '../Assets/Screenshot (735).png';
import eight from '../Assets/Screenshot (736).png';
import nine from '../Assets/Screenshot (743).png';
import zero from '../Assets/Screenshot (770).png';
import eleven from '../Assets/Screenshot (738).png';
import twelve from '../Assets/Screenshot (739).png';
import thirteen from '../Assets/Screenshot (740).png';
import fourteen from '../Assets/Screenshot (741).png';
import fifteen from '../Assets/Screenshot (781).png';

import { Link } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

export default function Projects() {
  return (
    <Grid container sx={{ width: '100%', height: '100%', margin: '0%', backgroundColor: 'black' }}>
      
      <ImageListItem key="Subheader" cols={2}></ImageListItem>
      {itemData.map((item) => (
         <Grid item xs={12} sm={12} md={12} lg={6}  >
        <ImageListItem key={item.img}>
          <img 
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=248&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={
              <Link  style= {{color:"white"}} 
              
              to={item.link}><Typography onMouseOver={(e) => e.currentTarget.style.color = '#00838f'} onMouseOut={(e) => e.currentTarget.style.color = 'white'} sx={{fontFamily: "Patua One", fontSize: '20px',fontWeight:'bold',textDecoration:"underline" }}>
                {item.title}
              </Typography></Link>
            }
            subtitle={
              <Typography sx={{ fontFamily: 'Mate SC', fontSize: '15px' }}>
                {item.details}
              </Typography>
            }
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                <InfoIcon />
              </IconButton>
            }
          />
        </ImageListItem>
        </Grid>
      ))}
    
    </Grid>
    
  );
}

const itemData = [
  {
    img: one,
    title: 'Consulting & Couching Website',
    details: 'html/css/ReactJS',
    link:'https://murouj-businesssolutions.com/',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: two,
    title: 'Cosmetics E-commerece Website',
    details: 'html/css/ReactJS',
    link:'https://hiyamcosmetics.com/',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: three,
    title: 'Consulting & Couching Website',
    details: 'html/css/ReactJS',
    link:'https://bureauconnect.net/',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: four,
    title: 'Security Management Website',
    details: 'html/css/ReactJS',
    link:'https://alertsolution.net/',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: five,
    title: 'Recruitment Solutions Website',
    details: 'html/css/ReactJS',
    link:'https://www.mabcareer.com/',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: fifteen,
    title: 'Engineering Inspection Website',
    details: 'html/css/ReactJS',
    link:'https://arch-olosimi.website/',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: zero,
    title: 'Landing Page Website',
    details: 'html/css/ReactJS',
    link:'https://landingpage-pos.netlify.app',
    rows: 2,
    cols: 2,
    featured: true,
  },
  /* {
    img: one,
    title: 'Mat Website',
    details: 'html/css/bootstrap/JS',
    link:'https://rajabbasha-mats.netlify.app/',
    rows: 2,
    cols: 2,
    featured: true,
  }, */
  {
    img: eleven,
    title: 'Social Platform Website ',
    link:'https://social-platform-server.netlify.app',
    details: 'html/css/JavaScript/API JavaScript',
    rows: 2,
    cols: 2,
  },
  /* {
    img: five,
    title: 'E-commerce Website',
    link:'https://e-commerece-db.netlify.app/',
    details: 'html/css/JS(React)',
    cols: 2,
  }, */
  
   
 /*  {
    img: nine,
    title: 'API Weather ',
    link:'https://weather-api-card.netlify.app/',
    details: 'html/css/React/Material UI',
    rows: 2,
    cols: 2,
  },
 
  {
    img: ten,
    title: 'Todo-List Website',
    link:'https://todos-list-crud.netlify.app',
    details: 'html/css/React/Material UI (CRUD)',
    
  }, */
  
  
];
