// App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar'
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProjectsPage from './pages/ProjectsPage';
import ResumePage from './pages/ResumePage';
import ServicesPage from './pages/ServicesPage';

function App() {
  return (
    <div className="App">
    
       <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/projects' element={<ProjectsPage />} />
          <Route path='/services' element={<ServicesPage />} />

          <Route path='/resume' element={<ResumePage />} />
        </Routes>
      </BrowserRouter> 
    </div>
  );
}

export default App;
