// HomePage.js

import React from 'react';
import Resume from '../components/Resume/Resume';
function ResumePage() {
  return (
    <div className='img' >
    
 <Resume/>
      
    </div>
  );
}

export default ResumePage;
 