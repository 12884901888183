import * as React from 'react';
import './Navbar.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';




const pages = [
    { label: 'Home', path: '/' },
    { label: 'About', path: '/about' },
    { label: 'Services', path: '/services' },
    { label: 'Resume', path: '/resume' },
  
    { label: 'Projects', path: '/projects' }
  ];
  

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  
  return (
    <AppBar position="static" sx={{backgroundColor:"black"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        
        
        <Typography  sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 ,fontSize:"40px", paddingRight:"10px",fontFamily: "Pacifico"}}>H</Typography>         

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
              
            </IconButton>
            
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                  <Typography className="navbar-link" sx={{fontFamily:"Lobster"}}
                  component={Link} to={page.path} textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography  sx={{ display: { xs: 'flex', md: 'none' }, mr: 1,fontFamily: "Pacifico" }} fontSize="40px" paddingRight="10px" >H</Typography>
          
          <Box sx={{ flexGrow: 1,display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button key={page.label}
               component={Link}
              to={page.path}
                
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block',':hover': {
            color:"#00838f"} }}
              >
                {page.label}
              </Button>
            ))}
            
          </Box>

       
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
