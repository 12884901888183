import './Resume.css';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import { Divider, List, StepContent, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import Groups2Icon from '@mui/icons-material/Groups2';
import CompressIcon from '@mui/icons-material/Compress';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSitemap, faWindowMaximize, faDatabase } from '@fortawesome/fontawesome-free-solid';
import { faFile } from '@fortawesome/fontawesome-free-solid';
import { faBootstrap, faReact } from '@fortawesome/free-brands-svg-icons';

const steps = [
  { label: 'Learned Turkish Language at Istanbul AKADMI.' },
  { label: 'Started Bachelor Degree of Computer Engineering at Bahçeşehir University' },
  { label: 'Started Minor program of Game Design at Bahçeşehir University' },
  { label: 'Graduated from Bachelor degree of Computer Engineering from Bahçeşehir University' }
];

const exper = [
  { label: 'App developer as an Intern for 2 months in ALEMI MEDYA Company' },
  { label: 'Secretary at KAA OFIS STASYONU Company for 1 year' }
];

const year = ['2021', '2022'];
const years = ['2014', '2017', '2018', '2021'];

const CustomStepIcon = (props) => {
  const { icon } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 45,
        height: 45,
        borderRadius: '50%',
        backgroundColor: '#00838f',
        color: '#fff',
      }}
    >
      {years[icon - 1]}
    </div>
  );
};

const CustomStepIcon1 = (props) => {
  const { icon } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 45,
        height: 45,
        borderRadius: '50%',
        backgroundColor: '#00838f',
        color: '#fff',
      }}
    >
      {year[icon - 1]}
    </div>
  );
};

const Resume = () => {
  return (
    <div className='main-3'>
      <Box className="background-image3">
        <Box sx={{ width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 5 }}>
            <Grid item xs={12} sm={6} sx={{ marginBottom: '5%' }}>
              <Typography className='edu' sx={{ textAlign: 'left', marginLeft: '21%', marginBottom: '3%', marginTop: '5%', fontSize: '25px', lineHeight: '1', fontWeight: 'bold', color: 'white', fontFamily: "Patua One" }}>SOFTWARE SKILLS</Typography>
              <div id="skill1" className="row">
                <div className="left">HTML</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="html">100%</span>
                    </span>
                  </li>
                </div>
              </div>
              <div id="skill2" className="row">
                <div className="left">CSS</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="css">95%</span>
                    </span>
                  </li>
                </div>
              </div>
              <div id="skill3" className="row">
                <div className="left">Javascript</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="javascript">70%</span>
                    </span>
                  </li>
                </div>
              </div>
              <div id="skill5" className="row">
                <div className="left">React JS </div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="react">70%</span>
                    </span>
                  </li>
                </div>
              </div>
              <div id="skill7" className="row">
                <div className="left">GitHub</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="gitHub">30%</span>
                    </span>
                  </li>
                </div>
              </div>
              <div id="skill4" className="row">
                <div className="left">Bootstrap</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="bootstrap">80%</span>
                    </span>
                  </li>
                </div>
              </div>
              <div id="skill6" className="row">
                <div className="left">Material UI</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="material">60%</span>
                    </span>
                  </li>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginBottom: '5%' }}>
              <Typography className='edu' sx={{ textAlign: 'left', marginLeft: '21%', marginBottom: '3%', fontSize: '25px', lineHeight: '1', fontWeight: 'bold', color: 'white', marginTop: '5%', fontFamily: "Patua One" }}>EDUCATION</Typography>
              <Box sx={{ marginTop: '3%', color: 'white', fontFamily: 'Mate SC' }}>
                <Stepper orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepLabel StepIconComponent={CustomStepIcon} sx={{ '& .MuiStepLabel-label.Mui-active': { color: 'white' }, '& .MuiStepLabel-label': { color: 'white', fontFamily: 'Mate SC' } }}>
                        {step.label}
                      </StepLabel>
                      <StepContent></StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginBottom: '5%' }}>
              <Typography className='edu' sx={{ textAlign: 'left', marginLeft: '21%', marginBottom: '3%', fontSize: '25px', lineHeight: '1', fontWeight: 'bold', color: 'white', fontFamily: "Patua One" }}>LANGUAGES</Typography>
              <div id="skill1" className="row">
                <div className="left">Arabic</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="arabic">100%</span>
                    </span>
                  </li>
                </div>
              </div>
              <div id="skill2" className="row">
                <div className="left">English</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="english">80%</span>
                    </span>
                  </li>
                </div>
              </div>
              <div id="skill3" className="row">
                <div className="left">Turkish</div>
                <div className="right">
                  <li className="li-skill">
                    <span className="main-bar">
                      <span className="turkish">50%</span>
                    </span>
                  </li>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginBottom: '5%' }}>
              <Typography className='edu' sx={{ textAlign: 'left', marginLeft: '21%', marginBottom: '3%', fontSize: '25px', lineHeight: '1', fontWeight: 'bold', color: 'white', fontFamily: "Patua One" }}>EXPERIENCES</Typography>
              <Box sx={{ marginTop: '3%', color: 'white' }}>
                <Stepper orientation="vertical">
                  {exper.map((experience, index) => (
                    <Step key={index}>
                      <StepLabel StepIconComponent={CustomStepIcon1} sx={{ '& .MuiStepLabel-label.Mui-active': { color: 'white' }, '& .MuiStepLabel-label': { color: 'white', fontFamily: 'Mate SC' } }}>
                        {experience.label}
                      </StepLabel>
                      <StepContent></StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginBottom: '5%'}}>
              <Typography className='edu' sx={{ textAlign: 'left', marginLeft: '21%', marginBottom: '3%', fontSize: '25px', lineHeight: '1', fontWeight: 'bold', color: 'white', fontFamily: "Patua One" }}>PERSONAL SKILLS</Typography>
              <Grid container spacing={1} columns={16} sx={{ marginTop: '3%', color: 'white' }}>
                <List sx={{ marginLeft: "5%" }}>
                  <ListItem> <Groups2Icon sx={{ paddingRight: "1rem", color: "#00b8d4" }} />Team Work</ListItem>
                  <ListItem> <CompressIcon sx={{ paddingRight: "1rem", color: "#d50000" }} />Work under pressure</ListItem>
                  <ListItem> <FontAwesomeIcon style={{ paddingRight: "1rem", color: "#607d8b" }} icon={faSitemap} />Organization</ListItem>
                  <ListItem><SyncProblemIcon sx={{ paddingRight: "1rem", color: "#ffeb3b" }} />Problem solving</ListItem>
                </List>
              </Grid>
            </Grid>
            
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default Resume;
