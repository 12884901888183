// HomePage.js
import React from 'react';
import Home from '../components/Home/Home';

function HomePage() {
  return (
    <div>
      <Home /> 
      
    </div>
  );
}

export default HomePage;
 