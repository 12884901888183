import './About.css';
import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';

const About = () => {
  return (
    <div className='main-2'>
      <Box className="background-image2">
        <Box sx={{ width: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Grid container spacing={0} columns={16}>
            <Grid item xs={16} sm={8} md={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div className='about' style={{ marginLeft: '15%', color: 'white', textAlign: 'left' }}>
                <Typography style={{ fontSize: '35px', fontWeight: 'bold', marginBottom: '0.2rem', lineHeight: '1', fontFamily: 'Paytone One', textDecoration: 'underline', textDecorationColor: 'teal' }}>ABOUT</Typography>
                <Typography style={{ fontSize: '15px', marginBottom: '6rem', fontFamily: 'Allura' }}>hagerelzamek77@gmail.com</Typography>
                <Typography className='top' style={{ fontSize: '20px', fontFamily: 'Philosopher', textAlign: 'left', marginBottom: '8rem', marginRight: '2rem' }}>
              

"A Frontend Developer with extensive experience in building websites for small and medium-sized businesses. Originally from Egypt, 24 years old, graduated in Turkey, and now residing in Istanbul."
                </Typography>
                <div style={{ marginBottom: '3rem', textAlign: 'center' }}>
                  <Divider color="#00838f" style={{ width: '50%', marginLeft: '15%', fontFamily: 'Lobster', marginBottom: '1rem' }}>
                    Based in <br />
                    Turkey, Istanbul <br />
                    <PushPinIcon sx={{ fontSize: 30, color: '#00838f', marginTop: '0.5rem' }} />
                  </Divider>
                </div>
              </div>
            </Grid>
            <Grid item xs={0} sm={8} md={10}></Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default About;
