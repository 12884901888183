// HomePage.js
import React from 'react';
import About from '../components/About.js/About';

function AboutPage() {
  return (
    <div>
    <About/>
      
    </div>
  );
}

export default AboutPage;
 