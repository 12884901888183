// HomePage.js
import React from 'react';
import Projects from '../components/Projects/Projects';

function ProjectsPage() {
  return (
    <div>
     <Projects/>
   
    </div>
  );
}

export default ProjectsPage;
 