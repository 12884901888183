import './Home.css';
import React from 'react';
import { Box, Grid, Stack, Typography, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import home from '../Assets/nikita-kachanovsky-OVbeSXRk_9E-unsplash.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPinterest, faWhatsapp ,faSquareUpwork, faSquareBehance} from '@fortawesome/free-brands-svg-icons';
import { faCircleDot } from '@fortawesome/free-regular-svg-icons/faCircleDot';

const Home = () => {
  return (
    <div className='main-1'>
      <Box className="background-image1">
        <Box sx={{ width: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Grid container spacing={0} columns={16}>
            <Grid item xs={16} sm={8} md={6}>
              <div style={{ marginTop: '20%', marginLeft: '10%', marginRight: '10%', textAlign: 'center' }}>
                <div style={{ marginLeft: "2%" }}>
                  <Typography style={{ fontFamily: "Montserrat", fontSize: "45px", color: "white", textAlign: "left", fontWeight: "300" }}>HAGAR</Typography>
                  <Typography style={{ fontFamily: "Archivo Black", fontSize: "50px", lineHeight: "0.5", color: "white", textAlign: "left", fontWeight: "500" }}>ELZAMEK</Typography>
                  <Typography style={{ fontFamily: "Montserrat", fontSize: "23px", lineHeight: "2.5", color: "white", textAlign: "left", fontWeight: "3", fontStyle: "normal" }}>FRONTEND DEVELOPER</Typography>
                </div>
                <Stack spacing={3} direction="row" sx={{ justifyContent: 'center', marginTop: 3 }}>
                  <Link to="/Services" style={{ textDecoration: 'none', flex: 1 }}>
                    <Button sx={{ width: '100%', border: '1px solid white', borderRadius: '15px', backgroundColor: 'transparent', color: 'white', ':hover': { border: '1px solid #00838f' } }} variant="outlined">Services</Button>
                  </Link>
                  <Link to="/Projects" style={{ textDecoration: 'none', flex: 1 }}>
                    <Button sx={{ width: '100%', border: '1px solid white', backgroundColor: 'transparent', color: 'white', borderRadius: '15px', ':hover': { border: '1px solid #00838f' } }} variant="outlined">Projects</Button>
                  </Link>
                </Stack>
                <Stack sx={{ color: 'white', justifyContent: 'center', alignItems: 'center', marginTop: 6 }} direction="row" spacing={3}>
                  <Link style={{ color: 'white' }} to='https://www.behance.net/hagarelzamek'>
                  <FontAwesomeIcon style={{ fontSize: 30, cursor: 'pointer' }} onMouseOver={(e) => e.currentTarget.style.color = 'blue'} onMouseOut={(e) => e.currentTarget.style.color = 'white'} icon={faSquareBehance} /> 
                  </Link>
                  
                  <Link style={{ color: 'white' }} to='https://www.instagram.com/hagar_elz?igsh=MWNxc3ZjY2Q3cHRoNw%3D%3D&utm_source=qr'>
                    <InstagramIcon sx={{ fontSize: 30, cursor: 'pointer', ':hover': { color: '#ec407a' } }} variant="outlined" />
                  </Link>
                  <Link style={{ color: 'white' }} to='mailto:hagerelzamek77@gmail.com'>
                    <EmailIcon sx={{ fontSize: 30, cursor: 'pointer', ':hover': { color: '#f3e5f5' } }} variant="outlined" />
                  </Link>
                 {/*  <Link style={{ color: 'white' }} to='https://api.whatsapp.com/send/?phone=905464010526&text&type=phone_number&app_absent=0'>
                    <FontAwesomeIcon style={{ fontSize: 30, cursor: 'pointer' }} onMouseOver={(e) => e.currentTarget.style.color = '#4caf50'} onMouseOut={(e) => e.currentTarget.style.color = 'white'} icon={faWhatsapp} />
                  </Link> */}
                  <Link style={{ color: 'white' }} to='https://tr.pinterest.com/hagarelzamek77/'>
                    <FontAwesomeIcon style={{ fontSize: 30, cursor: 'pointer' }} onMouseOver={(e) => e.currentTarget.style.color = '#dd2c00'} onMouseOut={(e) => e.currentTarget.style.color = 'white'} icon={faPinterest} />

                  </Link>
                  <Link style={{ color: 'white' }} to='https://mostaql.com/u/hagarelz_26'>
                  <FontAwesomeIcon style={{ fontSize: 30, cursor: 'pointer' }} onMouseOver={(e) => e.currentTarget.style.color = '#2196f3'} onMouseOut={(e) => e.currentTarget.style.color = 'white'} icon={faCircleDot} /> 
                  </Link>
                </Stack>
              </div>
            </Grid>
            <Grid item xs={0} sm={8} md={10}></Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
